<template>
  <div>
    <div v-if="error">
      <v-alert outlined type="error" prominent border="left">
        {{ errors }}
      </v-alert>
    </div>
    <div v-if="!sended">
      <v-card class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Сброс пароля</v-toolbar-title>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon large to="/login" v-on="on">
                <v-icon>mdi-home</v-icon>
              </v-btn>
            </template>
            <span>Страница входа</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon large to="/register" v-on="on">
                <v-icon>mdi-clipboard-account</v-icon>
              </v-btn>
            </template>
            <span>Регистрация</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="resetToken"
              label="Код"
              name="resetToken"
              prepend-icon="mdi-key"
              type="text"
            ></v-text-field>

            <v-text-field
              v-model="password"
              :counter="30"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              :error-messages="passwordErrors"
              id="password"
              label="Пароль"
              name="password"
              prepend-icon="mdi-lock"
              type="password"
            ></v-text-field>
            <v-text-field
              v-model="password2"
              :counter="30"
              @input="$v.password2.$touch()"
              @blur="$v.password2.$touch()"
              :error-messages="password2Errors"
              id="password2"
              label="Пароль еще раз"
              name="password2"
              prepend-icon="mdi-lock"
              type="password"
            ></v-text-field>
            Нет аккаунта?
            <router-link to="/register">Зарегистрируйтесь</router-link>.
            <vue-recaptcha
              ref="recaptcha"
              :sitekey="sitekey"
              :loadRecaptchaScript="true"
              @verify="validate"
              @expired="onCaptchaExpired"
            />
            <v-btn
              color="primary"
              @click.prevent="resetHandler"
              :loading="loading"
              :disabled="loading || !recaptchaToken"
              >Сохранить пароль</v-btn
            >
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
    <div v-else>
      <v-alert outlined type="success" prominent border="left">
        Пароль успешно изменен
        <v-btn color="blue lighten-2" text to="/#/login">
          На страницу входа
        </v-btn>
      </v-alert>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

import { VALID_ERRORS } from '../utils/mainConsts';
import { sendRequest } from '../utils/graphql';
import {
  required,
  minLength,
  maxLength,
  sameAs,
  email
} from 'vuelidate/lib/validators';

const captcha = process.env.VUE_APP_RECAPTCHA_TOKEN;

export default {
  name: 'ResetForm',
  components: { VueRecaptcha },
  props: ['token'],
  data: () => ({
    loading: false,
    error: false,
    sended: false,
    errors: '',
    resetToken: '',
    recaptchaToken: '',
    password: '',
    password2: '',
    sitekey: '6Lf9RtoZAAAAAHKY0erJVy-QRdD5oVth5uvPCAcL'
  }),
  validations: {
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(30)
    },
    password2: {
      required,
      match: sameAs('password')
    }
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength && errors.push(VALID_ERRORS.minLength(6));
      !this.$v.password.maxLength && errors.push(VALID_ERRORS.maxLength(30));
      !this.$v.password.required && errors.push(VALID_ERRORS.required);
      return errors;
    },
    password2Errors() {
      const errors = [];
      if (!this.$v.password2.$dirty) return errors;
      !this.$v.password2.match && errors.push(VALID_ERRORS.notMatch);
      !this.$v.password2.required && errors.push(VALID_ERRORS.required);
      return errors;
    }
  },
  mounted() {
    this.resetToken = this.token;
  },
  methods: {
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.recaptchaToken = '';
    },
    validate(value) {
      this.recaptchaToken = value;
    },
    async resetHandler() {
      this.$v.$touch();

      if (!this.$v.$invalid && this.recaptchaToken) {
        this.errors = '';
        const query = `
          mutation AuthResetPassword($token: String, $newPassword: String, $recaptchaToken: String) {
            authResetPassword(token: $token, newPassword: $newPassword, recaptchaToken: $recaptchaToken)
          }
        `;
        const vars = {
          token: this.resetToken,
          newPassword: this.password,
          recaptchaToken: this.recaptchaToken
        };

        this.loading = true;
        const result = await sendRequest('', query, vars);
        this.loading = false;

        if (
          result.errors &&
          Array.isArray(result.errors[0].data) &&
          result.errors[0].data.length > 0
        ) {
          this.recaptchaToken = '';
          this.$refs.recaptcha.reset();
          result.errors[0].data.forEach(element => {
            this.errors += element.message + ' ';
            this.error = true;
          });
        } else {
          this.error = false;
          this.sended = true;
        }
      }
    }
  }
};
</script>

<style></style>
